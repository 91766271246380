import { useAccount, useConfig } from "wagmi";
import { useCallback, useEffect, useState } from "react";
import { readContracts } from "@wagmi/core";
import Z314Abi from "../assets/abis/Z314.json";
import { STAKING } from "../constants";
import axios from "axios";
import request from "../../src/services/request";

function useStakingAccount() {
  const {address} = useAccount()
  const config = useConfig()
  const [value, setValue] = useState(null)
  const [refreshTime, setRefreshTime] = useState(Date.now())

  // const address = "0x22e41F481F6ab8388B431fB739e774159A4C3c57"

  useEffect(() => {
    const inc = setInterval(() => {
      setRefreshTime(Date.now())
    }, 3000)
    return () => clearInterval(inc)
  }, []);

  const fetch = useCallback(async () => {
    try {
      const results = await readContracts(config, {
        allowFailure: false,
        contracts: [
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'totalRewardAmount',
            args: []
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'getUserStakeAmount',
            args: [address]
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'earned',
            args: [address]
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'referrers',
            args: [address]
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'referrerAmount',
            args: [address]
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'referrerRewardAmount',
            args: [address]
          },
          {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'lastRewardAmount',
            args: []
          },
           {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'getRemainBNB',
            args: []
          },
           {
            abi: Z314Abi,
            address: STAKING,
            functionName: 'getStock',
            args: [address]
          }
        ]
      })
      const { data } = await request(`/api/getStack/${address}`, {
        method: 'get'
      })
      console.log("getStackdata",data);

      setValue({
        total: results[0],
        locked: results[1],
        pending: results[2],
        referrers: results[3],
        referrerAmount:results[4],
        referrerRewardAmount:results[5],
        last:results[6],
        remainBNB:results[7],
        stock:data.total
      })
    } catch (e) {
      console.log(e)
    }
  }, [address, config])

  useEffect(() => {
    if (config && address) {
      fetch()
    } else {
      setValue(null)
    }
  }, [fetch, config, address, refreshTime]);

  return value
}

export default useStakingAccount