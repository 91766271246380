import { FiChevronRight } from "react-icons/fi";
import { Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import tokenomics_top from '../../assets/images/tokenomics_top.png'
import boom from '../../assets/images/boom.png'
import tokenomics_left from '../../assets/images/tokenomics_left.png'
import tokenomics_right from '../../assets/images/tokenomics_right.png'
import tokenomics_left_cn from '../../assets/images/tokenomics_left_cn.png'
import tokenomics_right_cn from '../../assets/images/tokenomics_right_cn.png'

function Home() {
  const { t, i18n } = useTranslation();
  console.log("i18n", i18n)

  return (
    <div>
      <img src={tokenomics_top} className=" w-[28rem] m-auto" />
      <div className="text-white flex flex-row items-center ">
          <div className=" flex sm:flex-row flex-col mt-20 sm:mt-10 w-full text-4xl bg-no-repeat bg-contain bg-center h-96 m-auto text-center justify-center">
            {i18n.language == "zh" ? 
            <> 
              <img src={tokenomics_left_cn} className=" w-full sm:w-1/2 m-auto" />
              <img src={tokenomics_right_cn} className=" w-3/4 sm:w-2/6 m-auto" />
              </>
              :<>
              <img src={tokenomics_left} className=" w-full sm:w-1/2 m-auto" />
              <img src={tokenomics_right} className=" w-3/4 sm:w-2/6 m-auto" />
            </>}
           
          </div>
      </div>
    </div>
  );
}

export default Home;
