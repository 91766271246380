import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link, NavbarMenu, NavbarMenuItem, Button, NavbarMenuToggle,
  Select,
  SelectItem
} from "@nextui-org/react";
import Connect from "../Connect";
import Logo from "../Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setLang } from "../../utils/lang";

function Header() {
  const location = useLocation()
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {i18n, t} = useTranslation()

  const menuItems = [
    // {path: '/home', label: t("home")},
    {path: '/swap', label: t("swap")},
    // {path: '/launchpad', label: t("launch")},
    // {path: '/factory', label: t("factory")},
    // {path: '/markets', label: t("markets")},
    {path: '/staking', label: t("staking")},
    // {path: '/farm', label: t("farm")},
    // {path: '/bridge', label: t("bridge")},
    {path: '/tokenomics', label: t("tokenomics")},
    {path: '/about', label: t("about")}
  ];

  const changeLanguage = ([lng]) => {
    i18n.changeLanguage(lng);
    setLang(lng)
  }

  return (
    <Navbar maxWidth={'xl'}
            isBordered={false}
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            className=" bg-[#F85C43] drop-shadow"
            classNames={{
              wrapper: 'px-4',
            }}
    >
      <NavbarContent >
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="md:hidden outline-none "
        />
        <NavbarItem>
          <NavbarBrand>
            <Link className="font-bold text-inherit text-white dark:text-white text-lg pl-1" href={'/'}>
                <Logo/>{t('home')}
            </Link>
          </NavbarBrand>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className="hidden md:flex gap-4 justify-start items-center relative -left-72 " justify={"start"}>
        {menuItems.map((item) => (
          <NavbarItem key={item.label}>
            <Link className=" text-white" size={'lg'} color={location.pathname.indexOf(item.path) >= 0 ? 'primary' : 'foreground'} isBlock
                  href={item.path}>
              {item.label}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>

      <NavbarContent justify="end">
        <NavbarItem>
          <Select
            selectionMode="single"
            selectedKeys={[i18n.language]}
            label=""
            labelPlacement="outside"
            className="hidden md:block w-[7rem] !mt-0 bg-transparent bg-[#FF725B]"
            classNames={{
              "base":" bg-[#FF725B] round-full text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]",
              "mainWrapper": "bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "innerWrapper": "bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "label": "bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "listboxWrapper":"bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "listbox":"bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "popoverContent": "bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "selectorIcon":"bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "description":"bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "value":"bg-[#FF725B] text-white  data-hover:bg-[#FF725B] active:bg-[#FF725B]" ,
              "trigger":"bg-[#FF725B] data-hover:bg-[#FF725B] active:bg-[#FF725B] ",
              "helperWrapper":"bg-[#FF725B] data-hover:bg-[#FF725B] active:bg-[#FF725B] "
            }}
            onSelectionChange={changeLanguage}>
            <SelectItem key="en">English</SelectItem>
            <SelectItem key="zh">简体中文</SelectItem>
          </Select>
        </NavbarItem>
        <NavbarItem>
          <Connect/>
        </NavbarItem>
      </NavbarContent>

      <NavbarMenu>
        {menuItems.map((item) => (
          <NavbarMenuItem key={item.label}>
            <Button
              variant={'flat'}
              radius={'full'}
              color={location.pathname === item.path ? 'primary' : 'foreground'}
              className="w-full justify-start"
              onClick={() => {
                navigate(item.path)
                setIsMenuOpen(false)
              }}
            >
              {item.label}
            </Button>
          </NavbarMenuItem>
        ))}

        <Select
          selectionMode="single"
          selectedKeys={[i18n.language]}
          label=" "
          labelPlacement="outside"
          className="w-full !mt-0"
          onSelectionChange={changeLanguage}>
          <SelectItem key="en">English</SelectItem>
          <SelectItem key="zh">简体中文</SelectItem>
        </Select>
      </NavbarMenu>
    </Navbar>
  )
}

export default Header