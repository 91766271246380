import { FiChevronRight } from "react-icons/fi";
import { Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import boom from '../../assets/images/boom.png'
import twitter from '../../assets/images/icon_twitter.webp'
import telegram from '../../assets/images/icon_telegram.webp'
import etherscan from '../../assets/images/icon_etherscan.webp'
import gitee from '../../assets/images/icon_gitee.png'
import Swap from '../Swap/index'
import Staking from '../Staking/index'
import About from '../About/index'
import Tokenomics from '../Tokenomics/index'



function Home() {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-hidden before:absolute before:top-0 before:start-1/2 before:bg-no-repeat before:bg-top before:size-full before:-z-[1] before:transform before:-translate-x-1/2 before:bg-contain">
      <div class=" w-10/12 m-auto h-auto sm:min-h-screen text-white bg-[url('assets/images/home_bg.svg')] bg-no-repeat bg-center bg-cover  sm:bg-[length:70%_auto] sm:bg-[left_top_-15rem]">
        <div className=" flex flex-col sm:flex-row justify-center gap-4">
          <div className=" w-full  sm:w-2/3">
            <div className=" text-4xl sm:text-6xl my-20  leading-snug ">{t("All started with the ")}<br />{t("BIG BOOM!")}</div>
            <div className=" text-sm  sm:text-xl border border-[#FFB4A8] bg-[#ff725b] p-4 rounded-3xl ">Buy/Sell: 0xf1134726dd28e9c8eface7ed162b763a39c07888</div>
          </div>
          <img src={boom} className="w-2/5"/>
        </div>
        <div className="m-auto  w-12/12 flex justify-start   relative">
          <Link href="/swap"><button className=" bg-[#FACD7F] text-[#F85C43] py-4 px-20 rounded-full">Buy $BOOM</button></Link>
        </div>
      </div>
      <Swap />
      <Staking showLinkDialog={false} />
      <Tokenomics />
      <About />
      <div className=" p-40"></div>
    </div>
  );
}

export default Home;
