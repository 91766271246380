import { FiChevronRight } from "react-icons/fi";
import { Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import about_top from "../../assets/images/about_top.png";
import boom from "../../assets/images/boom.png";

function Home() {
  const { t } = useTranslation();

  return (
    <div className=" w-4/5 m-auto">
      <img src={about_top} className=" w-[34rem] m-auto" />
      <div className="text-white flex flex-col sm:flex-row items-center  ">
        <img src={boom} className=" w-1/4" />
        <div className=" text-2xl w-full">
          <h1 className="  mb-10">{t('$BOOM Trading Protocol')}</h1>
          <p className=" text-base">
            {t("Buy: Transfer $BNB to the contract address with your wallet to receive $BOOM")}
          </p>
          <p className=" text-base">
            {t("Sell: Transfer $BOOM to the contract address with your wallet to receive $BNB")}
          </p>
          <p>
            {t("Transaction cooling mechanism: 60 seconds transaction cooling mechanism to prevent robotic clip.")}
          </p>
        </div>
      </div>
      <div className=" text-white text-base w-full m-auto pb-20">
        <h1 className=" font-bold  text-2xl my-10">
          {t("Double-boom Destruction Mechanism")}
        </h1>

        <p>
          {t("BOOM 1 Auto-Boom: the liquidity pool burns and destroys 0.25% of the total amount of $BOOM in the liquidity pool per hour. ")}
        </p>

        <p>
         {t("BOOM 2 Boom with initiative: 2% of all buying and selling taxes go into the burning prize pool. Take the initiative to participate in the booming of $BOOM tokens to enjoy the dividends of $BNB.")}
        </p>

        <h1 className=" font-bold  text-2xl my-10">
          {t("Invite to Reward & Re-destruction")}
        </h1>

        <p>
          {t("When Boomers use your link to boom, you can receive a 10% reward in $BOOM tokens. If you were invited also, the person who invited you can receive a 5% reward in $BOOM tokens. The left part 85% $BOOM will be transferred to the (0xdead) address.")}
        </p>
      </div>
    </div>
  );
}

export default Home;
