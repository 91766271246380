import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { checksumAddress, isAddress } from "viem";
import { getSwaps, getToken } from "../../services/token";
import store from "../../store";
import { setSwaps, setToken } from "../../store/reducers/trade";
import { useSelector } from "react-redux";
import { BreadcrumbItem, Breadcrumbs } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { solidityCompiler, getCompilerVersions } from "@agnostico/browser-solidity-compiler";
import { useAccount, useWalletClient } from "wagmi";
import { encodeAbiParameters } from "viem";

const Factory = () => {
  const { t } = useTranslation()
  // const {address} = useParams()
    const { address, isConnected, connector } = useAccount();
    const { data: walletClient } = useWalletClient({ chainId: 56 });
    
    const [name, setName] = useState('ERC314')
    const [symbol, setSymbol]  = useState('ERC314')
    const [totalSupply, setTotalSupply] = useState(21000000)
    const [preSale, setPreSale] = useState(20)
    const [coolingBlock, setCoolingBlock] = useState(5)
    const [sellTax, setSellTax]  = useState(2)
    const [sellBurnTax, setSellBurnTax]  = useState(2)
    const [buyTax, setBuyTax]  = useState(2)
    const [buyBurnTax, setBuyBurnTax]  = useState(2)
  // 初始化代币信息
  useEffect(() => {

  }, []);

  const buildContact =  async() => {
    const source = `
// SPDX-License-Identifier: MIT
// OpenZeppelin Contracts (last updated v4.9.4) (utils/Context.sol)

pragma solidity ^0.8.0;

/**
 * @dev Provides information about the current execution context, including the
 * sender of the transaction and its data. While these are generally available
 * via msg.sender and msg.data, they should not be accessed in such a direct
 * manner, since when dealing with meta-transactions the account sending and
 * paying for execution may not be the actual sender (as far as an application
 * is concerned).
 *
 * This contract is only required for intermediate, library-like contracts.
 */
abstract contract Context {
function _msgSender() internal view virtual returns (address) {
    return msg.sender;
}

function _msgData() internal view virtual returns (bytes calldata) {
    return msg.data;
}

function _contextSuffixLength() internal view virtual returns (uint256) {
    return 0;
}
}

// OpenZeppelin Contracts (last updated v4.9.0) (access/Ownable.sol)

pragma solidity ^0.8.0;


/**
 * @dev Contract module which provides a basic access control mechanism, where
 * there is an account (an owner) that can be granted exclusive access to
 * specific functions.
 *
 * By default, the owner account will be the one that deploys the contract. This
 * can later be changed with {transferOwnership}.
 *
 * This module is used through inheritance. It will make available the modifier
 * onlyOwner, which can be applied to your functions to restrict their use to
 * the owner.
 */
abstract contract Ownable is Context {
address private _owner;

event OwnershipTransferred(address indexed previousOwner, address indexed newOwner);

/**
 * @dev Initializes the contract setting the deployer as the initial owner.
 */
constructor() {
    _transferOwnership(_msgSender());
}

/**
 * @dev Throws if called by any account other than the owner.
 */
modifier onlyOwner() {
    _checkOwner();
    _;
}

/**
 * @dev Returns the address of the current owner.
 */
function owner() public view virtual returns (address) {
    return _owner;
}

/**
 * @dev Throws if the sender is not the owner.
 */
function _checkOwner() internal view virtual {
    require(owner() == _msgSender(), "Ownable: caller is not the owner");
}

/**
 * @dev Leaves the contract without owner. It will not be possible to call
 * onlyOwner functions. Can only be called by the current owner.
 *
 * NOTE: Renouncing ownership will leave the contract without an owner,
 * thereby disabling any functionality that is only available to the owner.
 */
function renounceOwnership() public virtual onlyOwner {
    _transferOwnership(address(0));
}

/**
 * @dev Transfers ownership of the contract to a new account (newOwner).
 * Can only be called by the current owner.
 */
function transferOwnership(address newOwner) public virtual onlyOwner {
    require(newOwner != address(0), "Ownable: new owner is the zero address");
    _transferOwnership(newOwner);
}

/**
 * @dev Transfers ownership of the contract to a new account (newOwner).
 * Internal function without access restriction.
 */
function _transferOwnership(address newOwner) internal virtual {
    address oldOwner = _owner;
    _owner = newOwner;
    emit OwnershipTransferred(oldOwner, newOwner);
}
}

pragma solidity ^0.8.0;


interface IEERC314 {
event Transfer(address indexed from, address indexed to, uint256 value);
event Approval(
    address indexed owner,
    address indexed spender,
    uint256 value
);
event AddLiquidity(uint32 _blockToUnlockLiquidity, uint256 value);
event RemoveLiquidity(uint256 value);
event Swap(
    address indexed sender,
    uint amount0In,
    uint amount1In,
    uint amount0Out,
    uint amount1Out
);
}

abstract contract ERC314 is Context, Ownable, IEERC314 {
    mapping(address account => uint256) private _balances;
    mapping(address => mapping(address => uint256)) private _allowances;

    uint256 private _totalSupply;
    uint256 public _maxWallet;
    uint32 public blockToUnlockLiquidity;
    uint32 public coolingBlock;
    uint32 public sellTax;
    uint32 public buyTax;
    uint32 public sellBurnTax;
    uint32 public buyBurnTax;

    mapping(address => bool) public excludeCoolingOf;

    string private _name;
    string private _symbol;

    address public liquidityProvider;

    bool public liquidityAdded;

    uint256 public constant _rebaseDuration = 1 hours;
    uint256 public _rebaseRate = 25;
    uint256 public _lastRebaseTime;

    address payable public feeReceiver;


    mapping(address account => uint32) private lastTransaction;

    uint256 presaleAmount;
    bool public presaleEnable = false;

    modifier onlyLiquidityProvider() {
        require(
            _msgSender() == liquidityProvider,
            "You are not the liquidity provider"
        );
        _;
    }

    constructor(
        string memory name_,
        string memory symbol_,
        uint256 totalSupply_,
        uint32 _coolingBlock,
        uint32 _sellTax,
        uint32 _sellBurnTax,
        uint32 _buyTax,
        uint32 _buyBurnTax
    ) {
        _name = name_;
        _symbol = symbol_;
        _totalSupply = totalSupply_;

        _maxWallet = totalSupply_ / 200;

        coolingBlock = _coolingBlock;
        sellTax = _sellTax;
        buyTax = _buyTax;
        sellBurnTax = _sellBurnTax;
        buyBurnTax = _buyBurnTax;

        feeReceiver = payable(msg.sender);

        _lastRebaseTime = block.timestamp + 1 days;

        presaleAmount = (totalSupply_ * ${preSale}) / 100;

        uint256 liquidityAmount = totalSupply_ - presaleAmount;
        _balances[address(this)] = liquidityAmount;
    }

    function presale(address[] memory _investors) public onlyOwner {
        require(presaleEnable == false, "Presale already enabled");
        uint256 _amount = presaleAmount / _investors.length;
        for (uint256 i = 0; i < _investors.length; i++) {
            _balances[_investors[i]] += _amount;
        }
        presaleEnable = true;
    }

    function name() public view virtual returns (string memory) {
        return _name;
    }

    function symbol() public view virtual returns (string memory) {
        return _symbol;
    }

    function decimals() public view virtual returns (uint8) {
        return 18;
    }

    function totalSupply() public view virtual returns (uint256) {
        return _totalSupply;
    }

    function balanceOf(address account) public view virtual returns (uint256) {
        return _balances[account];
    }

    function allowance(
        address owner,
        address spender
    ) public view virtual returns (uint256) {
        return _allowances[owner][spender];
    }

    function approve(
        address spender,
        uint256 amount
    ) public virtual returns (bool) {
        address owner = _msgSender();
        _approve(owner, spender, amount);
        return true;
    }

    function transferFrom(
        address from,
        address to,
        uint256 amount
    ) public virtual returns (bool) {
        address spender = _msgSender();
        _spendAllowance(from, spender, amount);

        if (to == address(this)) {
            sell(from, amount);
        } else {
            _transfer(from, to, amount);
        }

        return true;
    }

    function transfer(address to, uint256 value) public virtual returns (bool) {
        // sell or transfer
        if (to == address(this)) {
            sell(_msgSender(), value);
        } else {
            _transfer(_msgSender(), to, value);
        }
        return true;
    }

    function _approve(
        address owner,
        address spender,
        uint256 amount
    ) internal virtual {
        require(owner != address(0), "ERC20: approve from the zero address");
        require(spender != address(0), "ERC20: approve to the zero address");

        _allowances[owner][spender] = amount;
        emit Approval(owner, spender, amount);
    }

    function _spendAllowance(
        address owner,
        address spender,
        uint256 amount
    ) internal virtual {
        uint256 currentAllowance = allowance(owner, spender);
        if (currentAllowance != type(uint256).max) {
            require(
                currentAllowance >= amount,
                "ERC20: insufficient allowance"
            );
            unchecked {
                _approve(owner, spender, currentAllowance - amount);
            }
        }
    }

    function _transfer(
        address from,
        address to,
        uint256 amount
    ) internal virtual {
        require(to != address(0), "ERC20: transfer to the zero address");

        if (!excludeCoolingOf[_msgSender()]) {
            require(
                lastTransaction[_msgSender()] + coolingBlock < block.number,
                "You can't make two transactions in the cooling block"
            );
            lastTransaction[_msgSender()] = uint32(block.number);
        }

        uint256 fromBalance = _balances[from];
        require(
            fromBalance >= amount,
            "ERC20: transfer amount exceeds balance"
        );
        unchecked {
            _balances[from] = fromBalance - amount;
            _balances[to] += amount;
        }

        emit Transfer(from, to, amount);
    }

    function _basicTransfer(address from, address to, uint256 amount) internal {
        require(
            _balances[from] >= amount,
            "ERC20: transfer amount exceeds balance"
        );

        unchecked {
            _balances[from] -= amount;
            _balances[to] += amount;
        }

        emit Transfer(from, to, amount);
    }

    function getReserves() public view returns (uint256, uint256) {
        return (address(this).balance, _balances[address(this)]);
    }

    function setMaxWallet(uint256 _maxWallet_) external onlyOwner {
        _maxWallet = _maxWallet_;
    }

    function setLastTransaction(
        address[] memory accounts,
        uint32 _block
    ) external onlyOwner {
        for (uint i = 0; i < accounts.length; i++) {
            lastTransaction[accounts[i]] = _block;
        }
    }

    function setExcludeCoolingOf(
        address[] memory accounts,
        bool _ok
    ) external onlyOwner {
        for (uint i = 0; i < accounts.length; i++) {
            excludeCoolingOf[accounts[i]] = _ok;
        }
    }

    function setBuyTax(uint32 _buyTax) external onlyOwner {
        require(_buyTax <= 50, "Tax is too big");
        buyTax = _buyTax;
    }

    function setSellTax(uint32 _sellTax) external onlyOwner {
        require(_sellTax <= 50, "Tax is too big");
        sellTax = _sellTax;
    }

    function setCooling(uint32 _coolingBlock) external onlyOwner {
        require(_coolingBlock <= 100, "Cooling is too big");
        coolingBlock = _coolingBlock;
    }

    function addLiquidity(
        uint32 _blockToUnlockLiquidity
    ) public payable onlyOwner {
        require(liquidityAdded == false, "Liquidity already added");

        liquidityAdded = true;

        require(msg.value > 0, "No ETH sent");
        require(block.number < _blockToUnlockLiquidity, "Block number too low");

        blockToUnlockLiquidity = _blockToUnlockLiquidity;
        liquidityProvider = _msgSender();

        emit AddLiquidity(_blockToUnlockLiquidity, msg.value);
    }

    function removeLiquidity() public onlyLiquidityProvider {
        require(block.number > blockToUnlockLiquidity, "Liquidity locked");

        liquidityAdded = false;

        payable(liquidityProvider).transfer(address(this).balance);

        emit RemoveLiquidity(address(this).balance);
    }

    function extendLiquidityLock(
        uint32 _blockToUnlockLiquidity
    ) public onlyLiquidityProvider {
        require(
            blockToUnlockLiquidity < _blockToUnlockLiquidity,
            "You can't shorten duration"
        );

        blockToUnlockLiquidity = _blockToUnlockLiquidity;
    }

    function getAmountOut(
        uint256 value,
        bool _buy
    ) public view returns (uint256) {
        (uint256 reserveETH, uint256 reserveToken) = getReserves();

        if (_buy) {
            return (value * reserveToken) / (reserveETH + value);
        } else {
            return (value * reserveETH) / (reserveToken + value);
        }
    }

    function buy() internal {
        require(liquidityAdded, "Trading not enable");
        require(msg.sender == tx.origin, "Only external calls allowed");

        address owner = _msgSender();

        uint256 tokenAmount = (msg.value * _balances[address(this)]) /
            (address(this).balance);

        require(
            tokenAmount + _balances[owner] <= _maxWallet,
            "Max wallet exceeded"
        );

        uint256 fee = (tokenAmount * buyTax) / 100;
        _transfer(address(this), owner, tokenAmount - fee);
        _basicTransfer(address(this), address(0xdead), fee);

        uint256 feeValue = msg.value * buyBurnTax / 100;
        payable(feeReceiver).transfer(feeValue);

        emit Swap(owner, msg.value, 0, 0, tokenAmount);
    }

    function sell(address owner, uint256 amount) internal {
        require(liquidityAdded, "Trading not enable");
        require(msg.sender == tx.origin, "Only external calls allowed");

        uint256 fee = (amount * sellTax) / 100;
        uint256 sellAmount = amount - fee;

        uint256 ethAmount = (sellAmount * address(this).balance) /
            (_balances[address(this)] + sellAmount);

        require(ethAmount > 0, "Sell amount too low");
        require(
            address(this).balance >= ethAmount,
            "Insufficient ETH in reserves"
        );

        _transfer(owner, address(this), amount);
        _basicTransfer(address(this), address(0xdead), fee);

        uint256 feeValue = msg.value * sellBurnTax / 100;
        payable(feeReceiver).transfer(feeValue);
        payable(owner).transfer(ethAmount - feeValue);

        emit Swap(owner, 0, amount, ethAmount, 0);
    }

    function rebase() external {
        uint256 lastRebaseTime = _lastRebaseTime;
        if (0 == lastRebaseTime) {
            return;
        }

        uint256 nowTime = block.timestamp;
        if (nowTime < lastRebaseTime + _rebaseDuration) {
            return;
        }

        _lastRebaseTime = nowTime;

        uint256 poolBalance = _balances[address(this)];
        uint256 rebaseAmount = (((poolBalance * _rebaseRate) / 10000) *
            (nowTime - lastRebaseTime)) / _rebaseDuration;

        if (rebaseAmount > poolBalance / 2) {
            rebaseAmount = poolBalance / 2;
        }

        if (rebaseAmount > 0) {
            _basicTransfer(address(this), address(0xdead), rebaseAmount);
        }
    }

    receive() external payable {
        buy();
    }
}

pragma solidity ^0.8.0;

contract X314 is ERC314 {
    uint256 private _totalSupply = ${totalSupply};

    constructor() ERC314("${name}", "${symbol}", _totalSupply, ${coolingBlock}, ${sellTax}, ${sellBurnTax},  ${buyTax}, ${buyBurnTax}) {}
}

    `;
    console.log(source)

    const result = await solidityCompiler({
        version: `https://binaries.soliditylang.org/bin/soljson-v0.8.20+commit.a1b79de6.js`,
        contractBody: source,
        options:{
            optimizer:{enabled: true,
            runs: 200}
        }
    })
    console.log("result",result)

    const CONTRACT_BYTECODE  = result.contracts.Compiled_Contracts.X314.evm.bytecode.object;
    const ABI = result.contracts.Compiled_Contracts.X314.abi;

    const txid = await walletClient?.deployContract({
      abi: ABI,
      bytecode: CONTRACT_BYTECODE,
      args: [],
    });

  }


  return (
    <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2'}>
      <Breadcrumbs size={'lg'} className={'my-6'}>
        <BreadcrumbItem href={'/'}>{t("home")}</BreadcrumbItem>
        <BreadcrumbItem>{t("factory")}</BreadcrumbItem>
      </Breadcrumbs>
      <div className="bg-white rounded-lg shadow-lg p-10 shadow-orange-200">
        <ul className="grid  grid-rows-2 grid-flow-col gap-4">
            <li>
                <h1 className="font-bold text-xl mb-2">Name</h1>
                <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg" />
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">Symbol</h1>
                <input type="text" value={symbol} onChange={(e)=>setSymbol(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
             <li>
                <h1 className="font-bold text-xl mb-2">TotalSupply</h1>
                <input type="text" value={totalSupply} onChange={(e)=>setTotalSupply(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
             <li>
                <h1 className="font-bold text-xl mb-2">PreSale</h1>
                <input type="text" value={preSale} onChange={(e)=>setPreSale(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">CoolingBlock</h1>
                <input type="text" value={coolingBlock} onChange={(e)=>setCoolingBlock(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">SellTax</h1>
                <input type="text" value={sellTax} onChange={(e)=>setSellTax(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">SellBurnTax</h1>
                <input type="text" value={sellBurnTax} onChange={(e)=>setSellBurnTax(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">BuyTax</h1>
                <input type="text" value={buyTax} onChange={(e)=>setBuyTax(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg"/>
            </li>
            <li>
                <h1 className="font-bold text-xl mb-2">buyBurnTax</h1>
                <input type="text" value={buyBurnTax} onChange={(e)=>setBuyBurnTax(e.target.value)} className=" border-1 text-base outline-none p-2 border-grey-500 rounded-lg" />
            </li>
        </ul>
        <button onClick={()=>buildContact()} className="border px-4 py-2 rounded-lg bg-orange-600 text-white mt-10">Deploy ERC314 Token</button>
      </div>
    </div>
  )
}

export default Factory