import { Skeleton } from "@nextui-org/react";
import { formatUnits } from "viem";
import { useTranslation } from "react-i18next";
import { NumberCount } from "../../../components/NumberCount";
import { useMemo } from "react";
import axios from "axios";

export const Account = ({account}) => {

  console.log("account", account);

  const total = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.total, 18))
    }
    return 0
  }, [account])

  const remain = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.total - account.last, 18))
    }
    return 0
  }, [account])

   const referrers = useMemo(() => {
    if (account) {
      console.log("account.referrers", account.referrers)
      return account.referrers
    }
    return ""
  }, [account])

  const locked = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.locked, 18))
    }
    return 0
  }, [account])


  const pending = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.pending, 18))
    }
    return 0
  }, [account])

    const remainBNB = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.remainBNB, 18))
    }
    return 0
  }, [account])

    const stock = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.stock, 18))
    }
    return 0
  }, [account])


  


  const referrerAmount = useMemo(() => {
    if (account) {
      return parseFloat(account.referrerAmount)
    }
    return 0
  }, [account])


  const referrerRewardAmount = useMemo(() => {
    if (account) {
      return parseFloat(formatUnits(account.referrerRewardAmount, 18))
    }
    return 0
  }, [account])

  const {t} = useTranslation()
  return (
    <div
      className="flex flex-col gap-y-3 lg:gap-y-5  rounded-xl">
      <h1 className=" text-2xl font-bold">{t('BOOM Rewards Pool')}</h1>
       <dl className="flex justify-center items-center  gap-4">
          <dt className="pe-3 ps-3 w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold'}>
            {
          account ? (
            <NumberCount value={total} decimals={8}/>
          ) : (
            <Skeleton className={'h-3 my-1 rounded-lg w-20'}/>
          )
        }
          </div>
          <span className="block text-sm text-white text-start">{t("total")}</span>
        </dt>
        <dt className="pe-3 ps-3 w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold'}>
            {
          account ? (
            <NumberCount value={remainBNB} decimals={8}/>
          ) : (
            <Skeleton className={'h-3 my-1 rounded-lg w-20'}/>
          )
        }
          </div>
          <span className="block text-sm text-white text-start">{t("remain")}</span>
        </dt>
       </dl>
      
      <h1 className=" text-2xl font-bold">{t('My Rewards')}</h1>
      <dl className="flex justify-center items-center gap-4  ">
        <dt className="pe-3 ps-3 w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold text-white dark:text-white'}>
            {
              account ? (
                <NumberCount value={stock} decimals={8}/>
              ) : (
                <Skeleton className={'h-3 my-1 rounded-lg  w-20'}/>
              )
            }
          </div>
          <span className="block text-sm text-white text-start  whitespace-nowrap">{t("stack")}</span>
        </dt>
        <dt className="pe-3 ps-3 w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold text-white '}>
            {
              account ? (
                <NumberCount value={locked} decimals={8}/>
              ) : (
                <Skeleton className={'h-3 my-1 rounded-lg  w-20'}/>
              )
            }
          </div>
          <span className="block text-sm text-white text-start  whitespace-nowrap">{t("locked")}</span>
        </dt>
     
      </dl>
      <dl>
           <dd className="text-start pe-3 ps-3 ">
          <div className={'w-full flex justify-start text-sm font-semibold text-white '}>
            {
              account ? (
                <NumberCount value={pending} decimals={8}/>
              ) : (
                <Skeleton className={'h-3 my-1 rounded-lg  w-20'}/>
              )
            }
          </div>
          <span className="block text-sm text-white text-start whitespace-nowrap">{t("available")}</span>
        </dd>
      </dl>

        <h1 className=" text-2xl font-bold">{t('Invite to Reward')}</h1>

      <dl className="flex justify-center items-center gap-4  ">
          <dt className="pe-3 ps-3 w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold text-white '}>
            {
              account ? (
                <NumberCount value={referrerAmount} decimals={0}/>
              ) : (
                <Skeleton className={'h-3 my-1 rounded-lg  w-20'}/>
              )
            }
          </div>
          <span className="block text-sm text-white text-start">{t("referrerAmount")}</span>
        </dt>
        <dd className="text-start pe-3 ps-3  w-1/2">
          <div className={'w-full flex justify-start text-sm font-semibold text-white '}>
            {
              account ? (
                <NumberCount value={referrerRewardAmount} decimals={8}/>
              ) : (
                <Skeleton className={'h-3 my-1 rounded-lg  w-20'}/>
              )
            }
          </div>
          <span className="block text-sm text-white text-start">{t("referrerRewardAmount")}</span>
        </dd>
      </dl>
     
    </div>
  )
}
