import { useTranslation } from "react-i18next";
import { FaGithub, FaSlack, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import twitter from '../../assets/images/icon_twitter.webp'
import telegram from '../../assets/images/icon_telegram.webp'
import etherscan from '../../assets/images/icon_etherscan.webp'
import gitee from '../../assets/images/icon_gitee.png'

function Footer() {
  const { t } = useTranslation()
  return (
    <footer className="w-full py-5 px-4 sm:px-6 lg:px-8 mx-auto fixed bottom-0 left-0 right-0 ">
          <ul className=" flex flex-row text-2xl gap-2 justify-center">
          <a target="_blank" href="https://twitter.com/Boomcoin_bsc"><li className="rounded-full p-2 text-center"><img  className="w-8" src={twitter} /></li></a>
         <a target="_blank"  href="https://t.me/boom_bsc"> <li className="  rounded-full p-2 text-center"><img  className="w-8" src={telegram} /></li></a>
          <a target="_blank" href="https://bscscan.com/address/0xf1134726dd28e9c8eface7ed162b763a39c07888" ><li className="  rounded-full p-2 text-center"><img  className="w-8" src={etherscan} /></li></a>
          <a target="_blank" href="https://boom-4.gitbook.io/boom-documents/" ><li className="  rounded-full p-2 text-center"><img  className="w-8" src={gitee} /></li></a>
        </ul>
    </footer>
  )
}

export default Footer