import React, { useCallback, useState, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Tabs,
  Tab,
} from "@nextui-org/react";
import useStakingAccount from "../../hooks/useStakingAccount";
import { Account } from "./components/Account";
import { InputAmount } from "./components/InputAmount";
import { STAKING, X314, PG314 } from "../../constants";
import { Token } from "../../constants/types";
import useAllowance from "../../hooks/useAllowance";
import { useClient, useWriteContract } from "wagmi";
import stakingAbi from "../../assets/abis/Staking.json";
import Z314Abi from "../../assets/abis/Z314.json";
import { parseUnits } from "viem";
import { waitForTransactionReceipt } from "viem/actions";
import { useTranslation } from "react-i18next";
import { FiGift } from "react-icons/fi";
import { getReferrerFromLocal } from "../../utils/referrer";
import { ZeroAddress } from "ethers";
import { Share } from "./components/Share";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import runles_top from "../../assets/images/runles_top.png";
import dashboard_top from "../../assets/images/dashboard_top.png";
import runles from "../../assets/images/runles.png";
import runles_cn from "../../assets/images/runles_cn.png";
import { useAccount } from "wagmi";


function Staking(props) {
  const { t, i18n } = useTranslation();
  const x314 = new Token(56, PG314, 18, "BOOM", "BOOM");
  const [amount, setAmount] = useState("");
  const {address} = useAccount()
  let routerReferrer = getReferrerFromLocal()
  // console.log("addres123s", address,routerReferrer)
  const [referrer, setReferrer] = useState(routerReferrer);
  const account = useStakingAccount();

  const searchParams = new URLSearchParams(window.location.search);
  const ref = searchParams.get('ref');
  routerReferrer = ref == null? "0x0000000000000000000000000000000000000000" : ref;

  console.log("ref", ref, routerReferrer);

  const [depositing, setDepositing] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [loitering, setLoitering] = useState(false);
  const { writeContractAsync } = useWriteContract();
  const client = useClient();
  const [referrering, setReferrering] = useState(false);

  const {
    value: allowance,
    approve,
    loading: approving,
  } = useAllowance(x314, STAKING);

  let { showLinkDialog } = props

  console.log("showLinkDialog", showLinkDialog)

  if(showLinkDialog == "undefined"){
    showLinkDialog = true
  }

  const onDeposit = useCallback(async () => {
    setDepositing(true);
    try {
      const hash = await writeContractAsync({
        abi: Z314Abi,
        address: STAKING,
        functionName: "burnByReferrer",
        args: [
          parseUnits(amount, 18),
          routerReferrer,
        ],
      });

      await waitForTransactionReceipt(client, {
        hash,
        confirmations: 1,
      });

      setAmount("");
    } catch (e) {
      console.log(e);
    } finally {
      setDepositing(false);
    }
  }, [amount, client, writeContractAsync]);

  const onClaim = useCallback(async () => {
    setClaiming(true);
    try {
      const hash = await writeContractAsync({
        abi: Z314Abi,
        address: STAKING,
        functionName: "claim",
      });

      await waitForTransactionReceipt(client, {
        hash,
        confirmations: 1,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setClaiming(false);
    }
  }, [client, writeContractAsync]);

  const onLottery = useCallback(async () => {
    setLoitering(true);
    try {
      const hash = await writeContractAsync({
        abi: stakingAbi,
        address: STAKING,
        functionName: "tryLottery",
        gas: 250000,
      });

      await waitForTransactionReceipt(client, {
        hash,
        confirmations: 1,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoitering(false);
    }
  }, [client, writeContractAsync]);

  const linkReferrer = useCallback(async () => {
    console.log("referrer", referrer);
    setReferrering(true);
    try {
      const hash = await writeContractAsync({
        abi: Z314Abi,
        address: STAKING,
        functionName: "linkReferrer",
        args: [referrer],
      });

      await waitForTransactionReceipt(client, {
        hash,
        confirmations: 1,
      });

      setAmount("");
    } catch (e) {
      console.log(e);
    } finally {
      setReferrering(false);
    }
  }, [referrer, client, writeContractAsync]);


  const referrers = useMemo(() => {
    if (account) {
      console.log("account.referrers", account.referrers);
      return account.referrers;
    }
    return "";
  }, [account]);

  const [selected, setSelected] = React.useState("photos");
  const [showDialog, setShowDialog] = useState(true)

  return (
    <>
     {referrers == "0x0000000000000000000000000000000000000000" && referrer != "0x0000000000000000000000000000000000000000" && showLinkDialog && showDialog &&
     <div className="fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-50 z-20" onClick={(e)=>{
      setShowDialog(false)
      e.stopPropagation()
     }}>
      referrers: {referrers}
      <div className="inner dialog w-[40rem] h-[20rem] bg-white absolute left-1/2 -ml-80 top-1/2 -mt-48 rounded-md py-4 px-8">
        <h1 className=" flex justify-between">
          <b>Link Referrer</b>
          <b className=" cursor-pointer font-normal" onClick={()=>setShowDialog(false)}>X</b>
        </h1>
        <div className="py-20">
          <Card>
            <CardBody className={"space-y-8"}>
                  <div
                    className={`border pl-3 py-0 h-12 flex flex-col rounded-xl`}
                  >
                    <div className={"w-full max-h-8 flex align-middle"}>
                      <input
                        value={referrer}
                        className={
                          "w-full text-sm h-10 outline-none disabled:bg-transparent text-center bg-transparent"
                        }
                        inputMode="text"
                        placeholder={""}
                        onChange={(e) => setReferrer(e.target.value)}
                        // disabled={account.referrers!= ""}
                      />
                      <Button
                        color={"warning"}
                        className={"w-20 text-[#F85C43] bg-[#FACD7F]"}
                        size={"lg"}
                        variant={"flat"}
                        isLoading={referrering}
                        onClick={linkReferrer}
                      >
                        {t("link")}
                      </Button>
                    </div>
                  </div>
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
    }

     {selected == "Dashboard" &&  <img src={dashboard_top} className=" w-[26rem] m-auto" />}
     { selected == "Rules" &&   <img src={runles_top} className=" w-[30rem] m-auto" />}
     <div className="container mx-auto  max-w-2xl py-8 px-4 drop-shadow-none">
      <Tabs aria-label="Options" className=" pl-4"  classNames={{
          tabList: "gap-6 w-full relative rounded-none p-0 border-b border-divider bg-transparent drop-shadow-none" ,
          cursor: "w-full bg-[#FF725B] drop-shadow-none",
          tab: "max-w-fit px-5 h-12  text-white drop-shadow-none",
          tabContent: "group-data-[selected=true]:text-white drop-shadow-none text-white"
        }}
        selectedKey={selected}
         onSelectionChange={(key)=>{
            console.log("selected", key)
            setSelected(key)
         }}
        >
        <Tab key="Dashboard" title={t('Dashboard')}>
          <div className="container mx-auto  max-w-2xl py-8 px-4">
            <Card className="bg-[#FF725B] text-white py-4 px-8 mb-6">
              <CardHeader className="flex justify-between">
                <div className={"flex flex-col"}>
                  {/* <p className="text-md">{t("staking_mining")}</p> */}
                </div>
                <div className=" whitespace-nowrap">
                  <Share />
                </div>
              </CardHeader>
              <CardBody className={"space-y-8"}>
                <Account account={account} />
              </CardBody>
            </Card>
            <Card className="bg-[#FF725B] text-white mb-6 py-4 px-8  ">
              <CardHeader>
                <div className="inline-flex justify-start items-center">
                  <span className="text-xs font-semibold uppercase text-white dark:text-gray-400">
                    {t("referrers")} :{" "}
                    {referrers == "0x0000000000000000000000000000000000000000"
                      ? t("none")
                      : referrers}
                  </span>
                </div>
              </CardHeader>
              <CardBody className={"space-y-8"}>
                {referrer != "0x0000000000000000000000000000000000000000" && (
                  <div
                    className={`border pl-3 py-0 h-12 flex flex-col rounded-xl`}
                  >
                    <div className={"w-full max-h-8 flex align-middle"}>
                      <input
                        value={referrer}
                        className={
                          "w-full text-sm h-10 outline-none disabled:bg-transparent text-center bg-transparent"
                        }
                        inputMode="text"
                        placeholder={""}
                        onChange={(e) => setReferrer(e.target.value)}
                        // disabled={account.referrers!= ""}
                      />
                      <Button
                        color={"warning"}
                        className={"w-20 text-[#F85C43] bg-[#FACD7F]"}
                        size={"lg"}
                        variant={"flat"}
                        isLoading={referrering}
                        onClick={linkReferrer}
                      >
                        {t("link")}
                      </Button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
            <Card className="bg-[#FF725B] text-white  mb-6 py-4 px-8  ">
              <CardBody>
                <InputAmount
                  x314={x314}
                  amount={amount}
                  setAmount={setAmount}
                />
              </CardBody>
              <CardFooter className={"space-y-4 flex-col"}>
                <div className={"w-full flex space-x-4"}>
                  {allowance <= 0 ||
                  (amount > 0 &&
                    !isNaN(Number(amount)) &&
                    parseUnits(amount, x314.decimals) > allowance) ? (
                    <Button
                      color={"warning"}
                      className={"w-full text-[#F85C43] bg-[#FACD7F]"}
                      size={"lg"}
                      variant={"flat"}
                      isLoading={approving}
                      onClick={approve}
                    >
                      {t("approve")}
                    </Button>
                  ) : (
                    <Button
                      color={"primary"}
                      className={
                        "w-full disabled:pointer-events-none disabled:grayscale text-[#F85C43] bg-[#FACD7F]"
                      }
                      size={"lg"}
                      variant={"flat"}
                      isLoading={depositing}
                      onClick={onDeposit}
                      disabled={amount <= 0}
                    >
                      {account && account.total > 0 ? t("add") : t("start")}
                    </Button>
                  )}
                  {account && account.total > 0 ? (
                    <Button
                      color={"secondary"}
                      className={
                        "w-full disabled:pointer-events-none disabled:grayscale text-[#F85C43] bg-[#FACD7F]"
                      }
                      size={"lg"}
                      variant={"flat"}
                      isLoading={claiming}
                      disabled={account.pending <= 0}
                      onClick={onClaim}
                    >
                      {t("receive")}
                    </Button>
                  ) : null}
                </div>

                {/* {
            account ? (
              <Button
                color={'danger'}
                className={'w-full disabled:pointer-events-none disabled:grayscale'}
                size={'lg'}
                variant={'flat'}
                isLoading={loitering}
                disabled={account.hasLotteryToday || !account.lotteryEnable}
                onClick={onLottery}
                startContent={<FiGift/>}
              >
                {t("lottery")}
              </Button>
            ) : null
          } */}
              </CardFooter>
            </Card>
          </div>
        </Tab>
        <Tab key="Rules" title={t('Boom Rules')}>
          <div className="container mx-auto  max-w-2xl py-8 px-4">
             {i18n.language == "zh" ? 
              <img src={runles_cn} /> :
              <img src={runles} /> 
              }
          </div>
        </Tab>
      </Tabs>
      </div>
    </>
  );
}

export default Staking;
