/**
 * API
 * @type {string}
 */
export const BASE_URI = 'https://boomcoin.app';

/**
 * Websocket
 * @type {string}
 */
export const WS_URL = 'wss://longswap.app/ws'

/**
 * Main Route
 * @type {string}
 */
export const SWAP_ROUTER = '0x2a39a6fe1629b80FD0c5baA30e96428701DA956D'

/**
 * x314
 * @type {string}
 */
export const X314 = '0xf1134726Dd28e9c8EfACe7eD162B763A39c07888'

export const PG314 = '0xf1134726Dd28e9c8EfACe7eD162B763A39c07888'

/**
 * Stake Pool
 * @type {string}
 */
export const STAKING = '0xf1134726Dd28e9c8EfACe7eD162B763A39c07888'