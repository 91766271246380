import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Swap from "./pages/Swap";
import Footer from "./components/Footer";
import Orders from "./pages/Orders";
import Home from "./pages/Home";
import About from "./pages/About";
import Token from "./pages/Token";
import WebSocketContext from './contexts/WebSocket';
import Markets from "./pages/Markets";
import Staking from "./pages/Staking";
import Factory from "./pages/Factory";
import Tokenomics from "./pages/Tokenomics";
import useSetReferrerFromUrl from "./hooks/useSetReferrerFromUrl";

function App() {
  useSetReferrerFromUrl()

  return (
    <>
      <Header/>
      <main className={'relative flex-grow min-h-[calc(100vh)] md:min-h-[calc(100vh)]'}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/swap" element={<Swap/>}/>
          <Route path="/tokenomics" element={<Tokenomics/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/swap/orders" element={<Orders/>}/>
          <Route path="/markets" element={<Markets/>}/>
          <Route path="/markets/:address" element={<WebSocketContext><Token/></WebSocketContext>}/>
          <Route path="/tokens/:address" element={<WebSocketContext><Token/></WebSocketContext>}/>
          <Route path="/staking" element={<Staking/>}/>
          <Route path="/factory" element={<Factory/>}/>
        </Routes>
      </main>
      <Footer/>
    </>
  );
}

export default App;
